import {isEmpty} from 'lodash'
import {Suspense, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../cms/partials'
import OnboardedCandidatesList from '../modules/careers/components/onboardedCandidates/component/candidatesList'
import ExamCandidatesList from '../modules/careers/components/shortlistedCandidatesForExamReport/component/candidatesList'
import GroupDiscussionCandidatesList from '../modules/careers/components/shortlistedCandidatesForGroupDiscussion/component/candidatesList'
import InterviewCandidatesList from '../modules/careers/components/shortlistedCandidatesForInterviewReport/component/candidatesList'
import VacancyCandidatesList from '../modules/careers/components/vacancy/component/candidatesList'
import VacancyApplicant from '../modules/careers/components/vacancyApplicantReport/components/index'
import FileManager from '../modules/common/components/fileManager/components'
import {ProfileHeader} from '../modules/profileComponent/components/ProfileHeader'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RouteList} from './RoutesList'
import {IAuthState} from '../../app/modules/auth'
import CheckPermissions from '../modules/common/checkPermission'

export function PrivateRoutes() {
  const module: any = useSelector((state: any) => state.auth.module)
  const [authorizedRoute, setAuthorizedRoute] = useState<Array<string>>([])
  useEffect(() => {
    if (isEmpty(authorizedRoute)) {
      let newAuthorizedRoute = [...authorizedRoute]
      module?.moduleData?.map((modules: any) =>
        modules.children
          ? modules.children?.map((route: any) => {
              newAuthorizedRoute?.push(route?.alias)
            })
          : newAuthorizedRoute?.push(modules?.alias)
      )
      setAuthorizedRoute(newAuthorizedRoute)
    }
  }, [module])

  const renderRoute = RouteList?.filter((route, i: number) => {
    return authorizedRoute?.some((authPath) => route?.path?.includes(authPath))
  })

  /* {RouteList?.map(
          (route: any, i: number) =>
            authorizedRoute?.includes(route?.path) && (
              <Route path={route?.path} component={route?.component} key={i} exact={route?.exact} />
            )
        )} */

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/media-manager' component={FileManager} />
        <Route path='/profile' component={ProfileHeader} />
        {RouteList?.map((item, i: number) => (
          <Route path={item?.path} component={item?.component} key={i} exact={item?.exact} />
        ))}

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
      </Switch>
    </Suspense>
  )
}
