import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {FDProductManagerModel, SortFDProductManagerModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const FD_PRODUCT_MANAGER_DATA = `${API_URL}/fd-product-manager`

export const service = {
  getAllFDProductManager: (params: ParamsModel) => {
    return axios.get(FD_PRODUCT_MANAGER_DATA, {params})
  },

  enableFDProductManager: (data: Array<string>) => {
    const fdProductManager = {
      fdProductManagerId: data,
    }
    return axios.put(`${FD_PRODUCT_MANAGER_DATA}/enable`, fdProductManager)
  },

  disableFDProductManager: (data: Array<string>) => {
    const fdProductManager = {
      fdProductManagerId: data,
    }
    return axios.put(`${FD_PRODUCT_MANAGER_DATA}/disable`, fdProductManager)
  },

  singleEnableFDProductManager: (data: Array<string>) => {
    const selectedProductManager = {
      fdProductManagerId: [data],
    }
    return axios.put(`${FD_PRODUCT_MANAGER_DATA}/enable`, selectedProductManager)
  },

  singleDisableFDProductManager: (data: Array<string>) => {
    const selectedProductManager = {
      fdProductManagerId: [data],
    }
    return axios.put(`${FD_PRODUCT_MANAGER_DATA}/disable`, selectedProductManager)
  },

  deleteFDProductManager: (data: {id: string}) => {
    return axios.delete(FD_PRODUCT_MANAGER_DATA, {data})
  },

  addFDProductManager: (data: any) => {
    return axios.post(FD_PRODUCT_MANAGER_DATA, data)
  },

  updateFDProductManager: (data: FDProductManagerModel, id: string) => {
    return axios.put(`${FD_PRODUCT_MANAGER_DATA}/${id}`, data)
  },

  sortFDProductManager: (body: SortFDProductManagerModel) => {
    return axios.put(`${FD_PRODUCT_MANAGER_DATA}/sort`, body)
  },
}
