import {useRef, useEffect} from 'react'
import clsx from 'clsx'
import {AsideMenuMain} from './AsideMenuMain'
import {useSelector} from 'react-redux'
import {IModuleModel} from 'src/app/modules/siteSettings/components/moduleManager/Model'
import {isEmpty} from 'lodash'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../assets/ts/components'
import {useLocation} from 'react-router'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const module: any = useSelector((state: any) => state.auth.module)

  const {pathname} = useLocation()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
  }, [pathname])

  const subRoutesToExclude = [
    '/update-faqs/:id',
    '/new-cif/new-cif-review/:id',
    '/new-cif/new-cif-review/checker/:id',
    '/kyc/kyc-update-list/:id',
    '/cpu-maker/new-cif-and-account-list/:id',
    '/cpu-maker/new-cif-and-account-list/corporate-bank/:id',
    '/cpu-checker/new-cif-and-account-list/:id',
    '/create-new-cif-and-account/biometric-verification',
    '/branch-checker/new-cif-and-account-list/:id',
    '/branch-checker/new-cif-and-corporate-account-list/:id',
    '/create-new-cif-and-account/update-and-review/:id',
    '/create-new-cif-and-account/biometric-verification/:id',
    '/linked-entities/linked-entities-review/:id',
    '/branch-maker/linked-entities/review-linked-entities/:id',
    '/cpu-checker/new-cif-and-account-list/corporate/:id',
    '/existing-cif/existing-cif-listing/:id',
    '/existing-cif/existing-cif-review/:id',
    '/kyc/kyc-update-list/checker/:id',
    '/kyc/kyc-update-list/branch-maker/:id',
    `/ancillary-service/branch-checker-review/:id`,
  ]

  const routeData = !isEmpty(module?.moduleData)
    ? module?.moduleData.map((parentData: any) => ({
        route: parentData.alias,
        title: parentData.name,
        subRoutes: parentData?.children
          ?.filter((item: any) => {
            return !subRoutesToExclude.includes(item?.alias)
          })
          .map((childrenData: IModuleModel) => ({
            route: childrenData.alias,
            title: childrenData.name,
          })),
      }))
    : []

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        {/* <AsideMenuMain /> */}

        <AsideMenuMain data={routeData} />
      </div>
    </div>
  )
}

export {AsideMenu}