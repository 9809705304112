import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// rsuite
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'

// includes
import moment from 'moment'
import {SortType} from 'rsuite-table/lib/@types/common'
import Pagination from 'rsuite/Pagination'
import {StateParamsModel} from 'src/app/modules/common/Model'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import * as activityLogsRedux from '../index'
import {ActivityLogModel} from '../Model/Model'

const ActivityLogsPage = ({statuslogsData}:any) => {
  console.log(statuslogsData,"from");
  const logTableData=statuslogsData.map((status:any,index:number)=>({...status,sn:index+1}))
  const dispatch = useDispatch()
  const {data, loading} = useSelector((state: any) => state.activityLogs)
  const [params, setParams] = useState<StateParamsModel>({
    page: 1,
    limit: 10,
  })

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const Cell = Table.Cell

 

  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 60,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Previous Status',
      dataKey: 'previous_status',
      width: 200,
      cell: <Cell dataKey='previous_status' />,
      sortable: false,
    },
    {
      label: 'Current Status',
      dataKey: 'current_status',
      width: 300,
      cell: <Cell dataKey='current_status' />,
      sortable: false,
    },
    {
      label: 'Remarks',
      dataKey: 'remarks',
      flexGrow: 1,

      cell: <Cell dataKey='remarks' />,
      sortable: false,
    },
  
    {
      label: 'Date',
      dataKey: 'updated_date',
      width: 240,
      cell: <Cell dataKey='updated_date' />,
      sortable: false,
    },
  ]

  useEffect(() => {
    dispatch(activityLogsRedux.actions.getActivityLogs(params))
  }, [params])

  // const activityLogsData =
  //   data?.userActivityLog?.map((userActivity: ActivityLogModel, i: number) => ({
  //     ...userActivity,
  //     sn: (params?.page - 1) * params?.limit + (i + 1),
  //   })) ?? []

  const handleSort = (dataKey: string, sortType?: SortType) => {
    setParams({...params, orderBy: dataKey, order: sortType})
  }

  const handleRefresh = () => {
    dispatch(activityLogsRedux.actions.getActivityLogs(params))
  }

  const handleGetAllData = () => {
    setParams({...params, limit: data?.meta?.total})
    dispatch(activityLogsRedux.actions.getActivityLogs(params))
  }

  return (
    <>
      <div className='shadow p-3 bg-white rounded' data-cy='search-category-shadow-comp'>
        
        <div className='datatable' data-cy='search-category-datatable'>
          <RSuiteTable
            // showCheckbox={true}
            columns={columns}
            data={logTableData ||[]}
            showLoading={loading}
            handleSort={handleSort}
            data-cy='search-category-r-suite'
          />
          {/* <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            className='mt-5'
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({...params, page: value})}
            onChangeLimit={handleChangeLimit}
            data-cy='search-category-pagination'
          /> */}
        </div>
      </div>
    </>
  )
}

export default ActivityLogsPage
