import axios from 'axios'
import {useQuery, useMutation} from 'react-query'
import {ParamsModel} from 'src/app/modules/common/Model'
const API_URL=window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const BRANCH_API = `${API_URL}/branch`

// Query Key
const QUERY_KEY = 'getbranch'

export const useGetBranches = () => {
  return useQuery([QUERY_KEY], async () => {
    const {data} = await axios.get(BRANCH_API)
    return data
  })
}